import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Yearly Goals -- Summer Patch ;)`}</h2>
    <p>{`Explained further in `}<a parentName="p" {...{
        "href": "/2020-06-21-summer"
      }}>{`my summer kickoff post`}</a></p>
    <h3>{`Personal:`}</h3>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`beauty/self-care/skin-care`}</li>
      <li parentName="ul">{`[o]`}{` edit NaNoWriMo`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`NaNoWriMo 2020`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`start yearly review...now`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`write long-term articles`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`build a new wardrobe (checkout `}<a parentName="li" {...{
          "href": "/checklists"
        }}>{`checklists`}</a>{`)`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`clean/organize house`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`watch all studio ghibli movies :D (checkout `}<a parentName="li" {...{
          "href": "/checklists"
        }}>{`checklists`}</a>{`)`}</li>
    </ul>
    <h3>{`Tech:`}</h3>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Godot`}<ul parentName="li">
          <li parentName="ul">{`goal is 2D exploration of a cave (no combat necessary)`}</li>
        </ul></li>
    </ul>
    <h2>{`Yearly goals`}</h2>
    <p>{`Goals article: `}<a parentName="p" {...{
        "href": "/2019-11-26-personal-2019-review"
      }}>{`2020 yearly goals`}</a></p>
    <p>{`Personal:`}</p>
    <ul>
      <li parentName="ul">{`build a mechanical keyboard from scratch`}</li>
      <li parentName="ul">{`beauty/self-care/skin-care`}</li>
      <li parentName="ul">{`edit NaNoWriMo`}</li>
      <li parentName="ul">{`NaNoWriMo 2020`}</li>
      <li parentName="ul">{`start yearly review...now`}</li>
      <li parentName="ul">{`write long-term articles`}</li>
    </ul>
    <p>{`Tech:`}</p>
    <ul>
      <li parentName="ul">{`Rust`}</li>
      <li parentName="ul">{`Webflow`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      